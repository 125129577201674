import LandingPageLayout from "../components/landingPages/LandingPageLayout";
import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";

const LandingVippeloft = () => {
    const heroImage = <StaticImage
        src={"../images/landingpage/vippeloft/vippeloft_hero_vertical_rev2.jpg"}
        alt={"Vippeløft"}
        width={1920}
        sizes={"(max-width: 900px) 50vw, 100vw"}
        outputPixelDensities={outputPixelDensities}
    />;

    const heroImageDesktop = <StaticImage
        src={"../images/landingpage/vippeloft/vippeloft_hero_desktop.jpg"}
        alt={"Vippeløft"}
        width={1920}
        sizes={"(max-width: 900px) 50vw, 100vw"}
        outputPixelDensities={outputPixelDensities}
    />

    const gallery = [
        <StaticImage
            src={"../images/landingpage/vippeloft/gallery/1.jpg"}
            alt={"Vippeløft med farge"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/vippeloft/gallery/2.jpg"}
            alt={"Vippeløft med farge"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/vippeloft/gallery/3.jpg"}
            alt={"Vippeløft med farge"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/vippeloft/gallery/4.jpg"}
            alt={"Vippeløft"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/vippeloft/gallery/5.jpg"}
            alt={"Vippeløft med farge"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/vippeloft/vippeloft_hero_vertical_rev2.jpg"}
            alt={"Vippeløft med farge"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />
    ];

    const perks = [
        {
            image: <StaticImage
                src={"../images/landingpage/perks/specialist_perk_rev2.jpg"}
                alt={"Grunnlegger Benedikte Villmark"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "Utføres av spesialister"
        },
        {
            image: <StaticImage
                src={"../images/landingpage/vippeloft/vippeloft_hero_vertical_rev2.jpg"}
                alt={"Øye med vippeløft"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "Vi tilpasser vippene til dine øyne"
        },
        {
            image: <StaticImage
                src={"../images/landingpage/perks/location_rev2.jpg"}
                alt={"Salongen på Grünerløkka"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "I Oslo Sentrum og på Grünerløkka"
        }
    ];

    const services = [
        {name: "Vippeløft", price: "650,-"},
        {name: "Vippeløft med farge", price: "890,-"},
        {name: "Vippeløft med farge for to personer", price: "1390,-"}
    ];

    const videoSrc = '/background_video/vippeloft_rev3.mp4';

    return(
        <LandingPageLayout
            title={"Vippeløft"}
            heroImage={heroImage}
            heroImageDesktop={heroImageDesktop}
            heroCaption={`
                Vippeløft skaper en illusjon av lengre vipper og gir deg et våkent uttrykk.
            `}
            text={`
                Vippeløft er en permanent bøy av dine naturlige øyevipper. 
                Når vippene blir bøyd oppover blir det en illusjon av lengre vipper og mer våkne øyne.
                Vippeløft varer i 4-8 uker og blir gradvis borte. Normalt holder vippeløft i ca 6 uker.
                En god kombinasjon er vippeløft og farging. Da vil effekten bli tydeligere, fordi man ser hårene bedre. 
                De fleste har lysere vipper ytterst og fargen vil få hele vippen til å synes. 
                Vi har en pakkeløsning med både farge og vippeløft.
            `}
            gallery={gallery}
            perks={perks}
            services={services}
            readMorePath={"/vippeloft"}
            videoSrc={videoSrc}
        />
    )
};

export default LandingVippeloft;