

import * as grid from "../../styles/grid.module.scss";
import * as styles from './landingpage.module.scss';
import ContentMargins from "../layout/ContentMargins";
import LandingPagePrice from "./landingPagePrice";
import Layout from "../layout/layout";
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import SeeAvailableButton from "../button/SeeAvailableButton";
import Seo from "../Seo";
import TertiaryHeading from "../contentHeadings/TertiaryHeading";
import ThreePerkCircles from "./ThreePerkCircles";
import { Link } from "gatsby";


const LandingPageLayout = props => {
    const { title, text, heroCaption, heroImage, heroImageDesktop, readMorePath, gallery, perks, services, videoSrc } = props;

    // The video should not start loading
    // until everything else is ready

    const [pageLoaded, setPageLoaded] = useState(false);
    useEffect(() => {
        if (document.readyState === 'complete'){
            setPageLoaded(true);
        } else {
            const onPageLoad = () => {
                if (document.readyState === 'complete'){
                    setPageLoaded(true);
                }
            }

            document.addEventListener('readystatechange', onPageLoad);
            return () => {
                document.removeEventListener('readystatechange', onPageLoad);
            };
        }
    }, []);


    // Next we're going to wait until the video
    // has loaded, before we fade out the foreground
    // image, and play the video

    const [videoLoaded, setVideoLoaded] = useState(false);

    const setVideoRef = useCallback(videoDOM => {
        if (!videoDOM){return;}

        if (videoDOM.readyState === 4){
            setVideoLoaded(true);
        } else {
            const listener = () => {
                setVideoLoaded(true);
                videoDOM.removeEventListener("canplaythrough", listener);
            }

            videoDOM.addEventListener('canplaythrough', listener);
        }
    }, []);

    const videoElement = useMemo(() => {
        if (!pageLoaded || !videoSrc){return null;}

        return(
            <video
                width={"720"}
                height={"720"}
                loop
                autoPlay
                muted
                playsInline
                className={styles.videoAnimationStart + (videoLoaded ? ` ${styles.videoAnimationEnd}` : "")}
                src={videoSrc}
                ref={setVideoRef}
            />
        )
    }, [pageLoaded, videoLoaded, videoSrc, setVideoRef]);

    return(
        <Layout>
            <Seo title={title}/>
            <div className={styles.heroWrapper}>
                <div className={styles.altHiddenMdUp}>
                    <div className={styles.heroImageMobile}>
                        {/*<Img*/}
                        {/*    fluid={heroImage}*/}
                        {/*    className={styles.imageAnimationStart + (videoLoaded ? ` ${styles.imageAnimationEnd}` : "")}*/}
                        {/*/>*/}
                        <div className={styles.imageAnimationStart + (videoLoaded ? ` ${styles.imageAnimationEnd}` : "")}>
                            { heroImage }
                        </div>
                        { videoElement }
                    </div>
                    <div className={styles.heroOverlayContainer + (videoLoaded ? ` ${styles.heroOverlayContainerVideoPlaying}` : "")}>
                        <h1 className={styles.heroTitle}>{ title }</h1>
                        <div className={styles.heroBottomContent}>
                            <p className={styles.heroBottomCaption}>{ heroCaption }</p>
                            <SeeAvailableButton className={styles.heroSeeAvailableButton}>Se ledige timer</SeeAvailableButton>
                        </div>
                    </div>
                </div>
                <div className={styles.altHiddenSmDown}>
                    {/*<Img*/}
                    {/*    fluid={heroImageDesktop}*/}
                    {/*    className={styles.heroImageDesktop}*/}
                    {/*/>*/}

                    <div className={styles.heroImageDesktop}>
                        { heroImageDesktop }
                    </div>
                    <div className={styles.heroOverlayContainer}>
                        <ContentMargins>
                            <h1 className={styles.heroTitle}>{ title }</h1>
                            <div className={styles.heroBottomContent}>
                                <p className={styles.heroBottomCaption}>{ heroCaption }</p>
                                <SeeAvailableButton className={styles.heroSeeAvailableButton}>Se ledige timer</SeeAvailableButton>
                            </div>
                        </ContentMargins>
                    </div>
                </div>
            </div>
            <ContentMargins>
                <p className={styles.whatIsBelowText}>{ title } hos Benedikte Vippeextensions:</p>
                <ThreePerkCircles
                    perks={perks}
                />
                <p className={styles.text}>
                    { text }
                    {" "}<Link to={readMorePath}>Les mer her</Link>.
                </p>
                <LandingPagePrice services={services}/>
                <SeeAvailableButton>Se ledige timer</SeeAvailableButton>
                <TertiaryHeading className={styles.tertiaryHeading}>Bilder</TertiaryHeading>
                <div className={grid.container}>
                    {
                        gallery.map((data, index) => (
                            <div className={`${grid.xs6Md4P8} ${styles.image}`} key={`gallery_image_${index}`}>
                                {/*<Img*/}
                                {/*    fluid={data.image}*/}
                                {/*    alt={data.alt}*/}
                                {/*    className={styles.image}*/}
                                {/*/>*/}
                                { data }
                            </div>
                        ))
                    }
                </div>
            </ContentMargins>
            <p className={styles.toFrontPageLink}>
                <Link to={"/"}>Til forsiden</Link>
            </p>
        </Layout>
    )
};

LandingPageLayout.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.any,
    heroImage: PropTypes.any,
    heroCaption: PropTypes.string.isRequired,
    gallery: PropTypes.any
}

export default LandingPageLayout;