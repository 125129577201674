
import * as grid from '../../styles/grid.module.scss';
import * as styles from './three-perk-circles.module.scss';
import React from 'react';

/*
* The perks prop should look like this:
* [
*   {image: <img from sharp>, caption: <string>, alt: <string>}
*   ...two more perks
* ]
* */

const ThreePerkCircles = props => {
    const { perks } = props;

    return(
        <div className={grid.container}>
            {
                perks.map(perk => (
                    <div className={grid.xs4P8} key={perk.caption}>
                        <figure>
                            {/*<Img*/}
                            {/*    fluid={perk.image}*/}
                            {/*    alt={perk.alt}*/}
                            {/*    className={styles.imageCircle}*/}
                            {/*/>*/}
                            <div className={styles.imageCircle}>
                                { perk.image }
                            </div>
                            <figcaption>
                                <p className={styles.caption}>
                                    { perk.caption }
                                </p>
                            </figcaption>
                        </figure>
                    </div>
                ))
            }
        </div>
    )
};

export default ThreePerkCircles;